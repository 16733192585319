import cx from 'classnames';
import Head from 'next/head';
import Link from 'next/link';
import dir from 'utils/dir';
import Container from 'components/container';
import Button from 'components/button';
import styles from './style.module.scss';

const Index = () => (
    <div>
        <Head>
            <title>{dir.Index.title}</title>
            <meta name="description" content={dir.Index.description} />
        </Head>
        <Container>
            <h1 className={styles['Index-header']}>
                Relentless forward progress.
            </h1>
            <p className={cx(styles['Index-sub'], 'stacked-xl')}>
                Torre Financial is an independent investment advisory firm
                focused on high quality companies. We invest in companies with
                high return on capital, competitive advantages, and durable
                growth. We call them compounders.
            </p>
            <Link href={dir.About.path}>
                <Button size="lg">Read more</Button>
            </Link>
        </Container>
    </div>
);

export default Index;
