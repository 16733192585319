import cx from 'classnames';
import styles from './style.module.scss';

const Button = ({
    className,
    type,
    size,
    disabled,
    onClick,
    full,
    ...props
}) => {
    const classes = cx(styles.Button, className, {
        [styles['Button-sm']]: disabled,
        [styles['Button-sm']]: size === 'sm',
        [styles['Button-md']]: !size || size === 'md',
        [styles['Button-lg']]: size === 'lg',
        [styles['Button-default']]: !type || type === 'default',
        [styles['Button-primary']]: type === 'primary',
        [styles['Button-secondary']]: type === 'secondary',
        [styles['Button-success']]: type === 'success',
        [styles['Button-alert']]: type === 'alert',
        [styles['Button-full']]: full
    });

    return (
        <button
            className={classes}
            onClick={disabled ? () => false : onClick}
            {...props}
        />
    );
};

export default Button;
